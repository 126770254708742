<template>
	<a-layout>
		<a-layout-sider style="background-color: #FFF;">
			<div style="width:90%;overflow: hidden;text-overflow: ellipsis;">
				<a-directory-tree :load-data="onLoadData" :show-line="false" show-icon
					:replaceFields="{ children: 'children', title: 'name', key: 'organId' }" :tree-data="treeData"
					@select="doStaff" />
			</div>
		</a-layout-sider>
		<a-layout-content style="background-color: #FFF;">
			<div>
				<a-layout>
					<div class="content-header">
						<a-affix :offset-top="0" style="width: 100%;">
							<a-row type="flex" justify="space-between">
								<a-col span="12"></a-col>
								<a-col span="12">
									<a-row type="flex" align="middle" justify="end" :gutter="3">
										<a-col span="12">
											<a-input v-model="param.keyword" allowClear search @pressEnter="getList"
												@on-clear="getList" placeholder="可输入发布人姓名查询"></a-input>
										</a-col>
										<a-col span="6" v-if="getOrganId() <= 10000">
											<a-select v-model="param.organId" style="width:100%" @change="e => {
																filterOrgan(e);
																this.getList();
															}
																" placeholder="根据单位筛选">
												<a-select-option value="">所有单位</a-select-option>
												<a-select-option :value="organ.organId" v-for="(organ, index) in organList"
													:key="index">{{ organ.name
																										}}</a-select-option>
											</a-select>
										</a-col>
									</a-row>
								</a-col>
							</a-row>
						</a-affix>
					</div>
				</a-layout>

				<a-table :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false"
					:indentSize="25" :style="{ background: '#FFF' }" size="small" row-key="id" border ref="list"
					:loading="loading" :data-source="data.records">
					<template slot-scope="row" slot="author">
						<a @click="$refs.detail.show(row)">
							<span>{{ row.author }}</span>
						</a>
					</template>
					<!--操作-->
					<template slot-scope="row" slot="action">
						<a-button-group shape="circle" size="small">
							<a-tooltip title="添加和查看点评列表"><a-button type="link" icon="eye"
									@click="$refs.comment._show(row.id)"></a-button></a-tooltip>
						</a-button-group>
					</template>
					<template slot-scope="row" slot="content">
						<a @click="$refs.detail.show(row)">
							<div class="contentStyle" v-html="row.content.substr(0, 40)"></div>
						</a>
					</template>
				</a-table>
				<a-row>
					<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
						<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
							@showSizeChange="setLimit" :current="data.current" />
					</a-col>
				</a-row>
				<comment-list ref="comment"></comment-list>
				<detail ref="detail"></detail>
			</div>
		</a-layout-content>
	</a-layout>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import detail from './components/detail.vue';
import commentList from './components/commentList.vue';
import db from '@/common/localstorage';
export default {
	components: { detail, commentList },
	data() {
		return {
			data: {},
			loading: false,
			organList: apiUtil.getOrganList(),
			pageSizeOptions: ['10', '20', '30', '40', '50'],
			pageSize: 10,
			total: '',
			param: {
				current: 1,
				limit: 20,
				keyword: '',
				organId: apiUtil.getOrganId(''),
			},
			columns: [
				{ title: 'ID', dataIndex: 'id', width: 80, ellipsis: true, align: 'center' },
				{ title: '姓名', scopedSlots: { customRender: 'author' }, width: 200, ellipsis: true, align: 'center' },
				// { title: '日志内容', scopedSlots: { customRender: 'content' }, ellipsis: true, align: 'left' },
				{ title: '日期', dataIndex: 'date', width: 160, ellipsis: true, align: 'center' },
				{ title: '发布时间', dataIndex: 'createTime', width: 260, ellipsis: true, align: 'center' },
				{ title: '操作', scopedSlots: { customRender: 'action' }, width: 100 }
			],
			treeData: [],
			organId: '',
		};
	},
	mounted() {
		this.treeData = db.get('organ-list');
		this.getList();
	},
	methods: {
		...apiUtil,
		doStaff(obj, e) {
			let item = e.node.dataRef;
			if (item.isNull) {
				return;
			}
			if (item.staffId > 0) {
				this.param.keyword = item.name;
				this.param.organId = item.oId;
				this.organId = item.oId;
				this.doRefresh();
			} else {
				this.onLoadData(e.node)
			}
		},
		onLoadData(treeNode) {
			const current = treeNode.dataRef;
			if (current.organId) {
				this.organId = current.organId;
				this.param.organId = current.organId
			}
			const param = { organId: this.organId, depId: '' };
			if (current.depId) {
				param.depId = current.depId;
			}
			return new Promise(resolve => {
				if (treeNode.dataRef.children) {
					resolve();
					return;
				}
				this.http.get('/platform/info/getScopeDepUser', param).then(res => {
					const list = [];
					res.data.map(item => {
						let obj = { name: item.name, isLeaf: item.isLeaf, depId: item.depId, staffId: item.staffId, isNull: item.isNull, oId: item.organId };
						list.push(obj)
					})
					treeNode.dataRef.children = list;
					this.treeData = [...this.treeData];
					resolve();
				})
			})
		},
		// 获取车辆数据
		getList() {
			this.loading = true;
			let jsonData = JSON.parse(JSON.stringify(this.param))
			// if (jsonData.keyword != '') {
			// 	jsonData.keyword = encodeURIComponent(jsonData.keyword);
			// }
			this.http
				.get('/platform/work-log/subList', jsonData)
				.then(ret => {
					this.data = ret.data;
					this.total = data.total;
					this.param.current = data.current;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},

		// 筛选条件变化重新获取数据
		doRefresh() {
			this.param.current = 1;
			this.getList();
		},

		// 筛选单位
		filterOrgan(e) {
			this.param.organId = e;
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		}
	}
};
</script>

<style lang="less">
p {
	line-height: normal;
	padding: 0;
	margin: 0;
}
</style>
